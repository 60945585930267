import React from "react";
import { Redirect } from "react-router-dom";

interface IProps {
  logout: () => void;
}

const Logout = ({ logout }: IProps) => {
  React.useEffect(() => {
    logout();
  });
  return <Redirect push to="/" />;
};

export default Logout;
