import React from "react";
import { IFile, IFiles } from "../types";
import VideoPlayer from "./VideoPlayer";
import { useParams, useHistory } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";
const videoIcon = require("../assets/play-circle-regular.svg");

interface IProps {
  files: IFiles;
  token: string;
}

const ContentYear = ({ files, token }: IProps) => {
  let history = useHistory();
  const [userSelectedVideo, selectVideo] = React.useState<IFile | null>(null);

  React.useEffect(() => {
    if (!files.length) history.push(`${process.env.PUBLIC_URL}/`);
  }, [files, history]);

  const { year } = useParams();
  if (!year) return null;
  const content = files.find(data => data.year === parseInt(year, 10));
  if (!content) {
    return null;
  }

  const renderEmptyVideo = (video: IFile) => (
    <div className="col-md-4 col-lg-3 mb-3" key={video.name}>
      <div
        onClick={() => selectVideo(video)}
        className={`embed-responsive embed-responsive-16by9 d-flex align-items-center justify-content-center py-2 video-player-mock
          ${video.img ? "video-player-video" : "video-player-empty"}
          ${
            userSelectedVideo && video.name === userSelectedVideo.name
              ? "selected"
              : ""
          }
        `}
      >
        {video.img ? (
          <img className="w-100" alt="" src={video.img} />
        ) : (
          <img className="w-50" alt="" src={videoIcon} />
        )}
      </div>
    </div>
  );
  
  // const videos = content.files.length
  //   ? content.files.sort( (a,b) => {
  //     const fileA = parseInt(a.name.split('.')[0], 10);
  //     const fileB = parseInt(b.name.split('.')[0], 10);

  //     if( isNaN(fileA) && isNaN(fileB) ) {
  //       return a.name[0] > b.name[0] ? 1 : -1
  //     }
  //     return fileA - fileB
  //   })
  //   : [];
  //   console.log('vv', videos)
  const videos = content.files.length
          ? content.files.sort((a,b) => a.order - b.order)
          : [];

  return (
    <div className="row w-100 year-videos">
      <Breadcrumb year={content.year} />
      <div className="col-12">
        <div className="row">
          {userSelectedVideo && (
            <div className="col-12">
              <VideoPlayer
                video={userSelectedVideo}
                year={content.year}
                token={token}
              />
            </div>
          )}
          {videos.length ? (
            videos.map(renderEmptyVideo)
          ) : (
            <div className="col-12 small text-muted strong">
              Nic tutaj nie ma
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentYear;
