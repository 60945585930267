import React from "react";
import { Link } from "react-router-dom";

interface IProps {
  year?: number;
}

const Breadcrumb = ({ year }: IProps) => {
  return (
    <nav className="col-12">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to={`${process.env.PUBLIC_URL}/content`}>Archiwum</Link>
        </li>
        {year && (
          <li className="breadcrumb-item">
            <Link to={`${process.env.PUBLIC_URL}/content/${year}`}>{year}</Link>
          </li>
        )}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
