import React, { useEffect } from "react";
import { getFiles } from "../services";
import Loading from "./Loading";
import { IFiles } from "../types";
import ContentYearBox from "./ContentYearBox";
import Breadcrumb from "./Breadcrumb";
import { useHistory } from "react-router-dom";
interface IProps {
  loading: boolean;
  files: IFiles;
  token: string;
  setData: (data: object) => void;
}

const Content = ({ loading, files, setData, token }: IProps) => {
  let history = useHistory();

  useEffect(() => {
    async function getFilesAPI() {
      setData({ loading: true });
      await getFiles()
        .then(files => {
          setData({ loading: false, files });
        })
        .catch(e => {
          history.push(`${process.env.PUBLIC_URL}/error`);
        });
    }
    if (!files.length) getFilesAPI();
  }, [setData, files, history]); //just to prevent warning

  if (loading) return <Loading />;

  return (
    <div className="row justify-content-center">
      <Breadcrumb />
      {files.map(data => (
        <ContentYearBox
          key={data.year}
          year={data.year}
          isProtected={data.isProtected}
          isUserAuth={token.length > 0}
        />
      ))}
    </div>
  );
};

export default Content;
