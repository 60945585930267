import { API_URL } from "./constants";

const callAPI = (data: object) =>
  fetch(API_URL, {
    method: "POST",
    cache: "no-store",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({...data, t: Date.now()})
  })
    .then(async response => {
      const data = await response.json();
      return { status: response.status, data };
    })
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.data.message);
      }
      return response.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });

const getToken = (login: string) => callAPI({ action: "getToken", login });

const getFiles = () => callAPI({ action: "getFiles" });

const signUrl = (file: string, token: string) => callAPI({ action: "signUrl", file, token });

export { getToken, getFiles, signUrl };
