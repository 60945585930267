import React from "react";
import { Link } from "react-router-dom";
const yearImg = require("../assets/empty_year.png");
const lockIcon = require("../assets/lock.svg");

interface IProps {
  year: number;
  isProtected: boolean;
  isUserAuth: boolean;
}

const ContentYearBox = ({ year, isProtected, isUserAuth }: IProps) => {
  const [error, setError] = React.useState(false);
  return (
    <div className="col-md-3 mb-3">
      <div className="position-relative">
        <Link key={year} to={`${process.env.PUBLIC_URL}/content/${year}`}>
          <img
            className="img-fluid"
            alt=""
            src={error ? yearImg : `${process.env.PUBLIC_URL}/img/${year}.png`}
            onError={() => setError(true)}
          />
          {isProtected && !isUserAuth && <img alt="" src={lockIcon} className="position-absolute icon-locked" />}
        </Link>
      </div>
    </div>
  );
};

export default ContentYearBox;
