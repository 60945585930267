import React from "react";
import Login from "./content/Login";
import Content from "./content/Content";
import { IFiles } from "./types";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ContentYear from "./content/ContentYear";
import Logout from "./content/Logout";
import ErrorPage from "./content/ErrorPage";

interface IState {
  token: string;
  loading: boolean;
  files: IFiles;
}

const INITIAL_STATE = {
  token: "",
  loading: false,
  files: []
};

function PrivateRoute({ children, ...rest }) {
  const requestedYear = parseInt(rest.computedMatch.params.year, 10);
  const isProtected = () => {
    const filesFromYear = rest.files.find(file => file.year === requestedYear);
    if (!filesFromYear) return false;
    return filesFromYear.isProtected;
  };
  return (
    <Route
      {...rest}
      render={() => 
        isProtected() && !rest.token.length ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: requestedYear }
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}

class App extends React.Component<{}, IState> {
  state = INITIAL_STATE;

  logout = () => this.setState(INITIAL_STATE);

  setData = (data: object) => this.setState(data);

  render() {
    const { loading, token, files } = this.state;
    return (
      <>
        <div className="container-fluid">
          <div className="container">
            <div className="title d-flex flex-column align-items-center justify-content-center text-uppercase">
              <p className="m-0 title-first text-center">archiwum transmisji</p>
              <p className="m-0 text-center">warsaw live endoscopy</p>
            </div>
          </div>
        </div>

        <div className="container-fluid top-bg top">
          <div className="container top-bg-container top"></div>
        </div>

        <div className="container-fluid bottom-bg content d-flex align-items-center justify-content-center position-relative">
          <div className="container h-100 ">
            <div className="col-12 text-center">
              <Router>
                <Switch>
                  {/* <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/`}
                    render={() => (
                      <Redirect to={token.length ? `${process.env.PUBLIC_URL}/content` : `${process.env.PUBLIC_URL}/login`} />
                    )}
                  /> */}
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/`}
                    render={() => (
                      <Redirect to={`${process.env.PUBLIC_URL}/content`} />
                    )}
                  />
                  {/* <PrivateRoute exact token={token} path={`${process.env.PUBLIC_URL}/content`}>
                    <Content
                      loading={loading}
                      setData={this.setData}
                      files={files}
                    />
                  </PrivateRoute>
                  <PrivateRoute exact token={token} path={`${process.env.PUBLIC_URL}/content/:year`}>
                    <ContentYear files={files} token={token} />
                  </PrivateRoute> */}
                  <Route exact path={`${process.env.PUBLIC_URL}/content`}>
                    <Content
                      loading={loading}
                      setData={this.setData}
                      files={files}
                      token={token}
                    />
                  </Route>
                  <PrivateRoute
                    exact
                    token={token}
                    files={files}
                    path={`${process.env.PUBLIC_URL}/content/:year`}
                  >
                    <ContentYear files={files} token={token} />
                  </PrivateRoute>
                  <Route exact path={`${process.env.PUBLIC_URL}/login`}>
                    <Login
                      setData={this.setData}
                      loading={loading}
                      token={token}
                    />
                  </Route>
                  <Route exact path={`${process.env.PUBLIC_URL}/logout`}>
                    <Logout logout={this.logout} />
                  </Route>
                  <Route exact path={`${process.env.PUBLIC_URL}/error`}>
                    <ErrorPage />
                  </Route>
                </Switch>
              </Router>
            </div>
          </div>
          <div className="position-absolute version">
            v1.5
          </div>
        </div>
      </>
    );
  }
}

export default App;
