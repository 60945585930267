import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => (
  <div className="row">
    <div className="col-12">
      <div className="alert alert-danger">
        Mamy problemy techniczne, spróbuj za chwilę.
        <Link to={process.env.PUBLIC_URL} className="d-block btn btn-secondary mt-3" href="/">Spróbuj ponownie</Link>
      </div>
    </div>
  </div>
)

export default ErrorPage;
