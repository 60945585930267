import * as React from "react";
import ReactJWPlayer from "react-jw-player";
import { IFile } from "../types";
import { signUrl } from "../services";
import Loading from "./Loading";
import { useHistory } from "react-router-dom";

interface ISecure {
  file: string;
  token: string;
}

interface IProps {
  video: IFile;
  year: number;
  token: string;
}

const VideoPlayer = ({ video, year, token }: IProps) => {
  const [loading, setLoading] = React.useState(true);
  const [signedFile, setSignedFile] = React.useState<ISecure | null>(null);
  let history = useHistory();

  React.useEffect(() => {
    setLoading(true);
    async function signFile() {
      await signUrl(`/${year}/${video.name}`, token)
        .then(signedFile => {
          setSignedFile(signedFile);
          setLoading(false);
        })
        .catch(e => {
          history.push(`${process.env.PUBLIC_URL}/logout`);
        });
    }
    window.scrollTo(0, 0);
    signFile();
  }, [video.name, year, history, token]);

  if (loading || !signedFile)
    return (
      <div className="col-12 text-center my-3">
        <Loading />
      </div>
    );

  return (
    <div className="col-12 my-3">
      <ReactJWPlayer
        className="d-flex justify-content-center mt-1"
        licenseKey="JsZ8wqLZrUCTz6M19WKv7KSxhGUJ4Q7zOy0oszY1Yro="
        playerId={video.name}
        playerScript="https://archive.wlendoscopy.pl/jwplayer.js"
        file={signedFile.file}
        width="100%"
        aspectRatio="16:9"
        image={video.img ? video.img : undefined}
        autostart={true}
      />
    </div>
  );
};

export default VideoPlayer;
