import React from "react";
import { getToken } from "../services";
import Loading from "./Loading";
import { useHistory, RouteComponentProps, withRouter, useLocation } from "react-router-dom";


interface LocationProps {
  from?: string;
}
interface IProps extends RouteComponentProps {
  setData: (data: object) => any;
  loading: boolean;
  token: string;
}

const Login = ({ setData, loading, token, location }: IProps) => {
  const { state } = useLocation();
  const from = `/${state.from}` || ""

  const [login, setLogin] = React.useState("");
  const [error, setError] = React.useState("");
  
  let history = useHistory();
  React.useEffect(() => {
    if( token.length ) {
      history.push(`${process.env.PUBLIC_URL}/content${from}`)
    }
  }, [token, history, from]); //just to prevent warning

  const submitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setData({ loading: true });
    try {
      const token = await getToken(login);
      setData({ token });
    } catch (e) {
      setError(e.message);
    }
    setData({ loading: false });
  };

  const handleLoginChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setLogin(e.target.value);

  if (loading) return <Loading />;
  return (
    <div className="row justify-content-center">
      
    
    <form onSubmit={submitLogin} className="col-md-4">
      {error.length > 0 && <div className="alert alert-danger">{error}</div>}
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          placeholder="Proszę podać login"
          value={login}
          onChange={handleLoginChange}
        />
      </div>
      <button type="submit" className="btn btn-light w-100 text-center">
        Zaloguj się
      </button>
    </form>
    </div>
  );
};

export default withRouter(Login);
